.page {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;

  .step_box {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: space-between;
    gap: 20px;
    margin-top: -70px;

    @media screen and (max-width: 1070px) {
      margin-top: 0;
      padding: 0 10px;
    }

    .inactive_two {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }

  .connect_line {
    width: 100px;
    border-radius: 100px;
    height: 2px;
    box-shadow: 0 2px 2px #555;
    opacity: 0.3;
    background: #D9D9D9;
  }
}

.main {
  background-image: none;
  background-color: #524BDA;
}

.box_title {
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  letter-spacing: -0.02em;
  margin: 10px;
  color: #FFF;
  text-align: center;
  line-height: 76px; /* 172.727% */
}

.signInFormWrapper {
  padding: 40px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.sign_in_title {
  padding-left: 40px;
  margin-bottom: -25px;
}

.white_box {
  gap: 8px;
  //padding: 12px 25px;

  width: fit-content;
  height: auto;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4.8px 14.4px rgba(0, 0, 0, 0.24);
  border-radius: 24px;

  .box_title {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    letter-spacing: -0.02em;
    margin: 10px;
    max-width: 400px;
    color: #000000;
    text-align: center;
  }

  .top_text {
    font-size: 32px;
    @media screen and (max-width: 415px) {
      font-size: 26px;
    }
    padding: 5px;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    line-height: 60px; /* 187.5% */
    letter-spacing: -0.64px;
    white-space: nowrap;
  }

  .form_elements {
    margin: 48px 0;
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  .google_btn {
    border-radius: 24px;
    border: none;
    width: 100%;
    font-family: Roboto;

    display: inline-flex;
    align-items: center;
    background: #ffffff;
    color: rgba(0, 0, 0, 0.54);
    padding: 11px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.17), 0 0 1px 0 rgba(0, 0, 0, 0.08);

    .span {
      padding-left: 24px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .or_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  .inputLabel {
    font-family: Inter;
    color: var(--Text-Colors-textTertiary, #697785);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .inputLabelRequired {
    &::after {
      content: '*';
      color: #FA5252;
      font-family: inherit;
      font-size: 14px;
      font-style: normal;
      padding-left: 2px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .input {
    padding: 10px 12px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    margin: 4px 0;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
  }
  select:invalid,
  select option[value=''] {
    color: #848484;
    font-family: 'Montserrat', sans-serif;
  }

  .input::placeholder {
    color: #848484;
    font-family: 'Montserrat', sans-serif;
  }
  .input::-webkit-input-placeholder {
    color: #848484;
  }

  .input:-moz-placeholder {
    color: #848484;
    opacity: 1;
  }

  .input::-moz-placeholder {
    color: #848484;
    opacity: 1;
  }

  .input:-ms-input-placeholder {
    color: #848484;
  }

  .name_row {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .terms {
    display: flex;
    align-items: center;

    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      color: var(--Text-Colors-textSecondary, #414E5C);
      font-feature-settings: 'clig' off, 'liga' off;

      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px; /* 143.75% */
    }

    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      border-radius: 2px;
      background-color: white;
      border: 1px solid #AEBCCB;

      padding: 7px;
      justify-content: center;
      align-items: center;
    }

    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    .container input:checked ~ .checkmark {
      background-color: #2196f3;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .container input:checked ~ .checkmark:after {
      display: block;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 8px;
    }

    .container .checkmark:after {
      left: 3px;
      top: 1px;
      width: 3px;
      height: 7px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .terms_text {
      margin-left: 3px;
    }

    a {
      text-decoration: underline;
    }

    .terms_blue {
      color: #4e59e3;
    }
  }

  .recaptcha {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 5px;
  }

  .long_line {
    display: flex;
    width: 100%;
    flex: 1;
    height: 0;
    border-top: 1px solid #c4c4c4;
    margin-top: 5px;
  }

  .btn {
    border-radius: 12px;
    box-sizing: border-box;
    margin: 0px 0px;
    margin-top: 5px;
    padding: 16px 32px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Montserrat';
    line-height: 24px;

    border: none;
    color: var(--black-color);
    background: var(--yellow-color);
  }

  .error_msg_show {
    color: red;
    font-size: 12px;
    display: block;
    font-weight: 500;
    max-width: 400px;
  }
  .error_msg_hidden {
    color: transparent;
    font-size: 12px;
  }

  .success_msg_show {
    color: rgb(26, 198, 0);
    font-size: 15px;
    font-weight: 500;
  }
  .success_msg_hidden {
    font-size: 15px;
    color: transparent;
  }

  .coupon_field {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 5px;
  }

  .psw_text {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .forget_psw {
    font-size: 13px;
    margin-bottom: 15px;
    margin-top: 5px;
    font-weight: 500;
    cursor: pointer;
  }

  .back_text_container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-content: flex-start;
  }
}

.account_route {
  align-self: flex-start;
  margin: 10px 0;
  font-size: 14px;

  .route {
    font-weight: 700;
    margin-left: 5px;
    cursor: pointer;
    color: #4e59e3;
  }
}

.wrapper {
  display: flex;
  width: 1136px;

  @media screen and (max-width: 1200px) {
    flex-flow: column;
    width: 100%;
    max-width: 568%;
  }
}

.graybox {
  width: 50%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 48px;
  -moz-border-radius-topright: 25px;
  -moz-border-radius-bottomright: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background: #F8F8FE;
  @media screen and (max-width: 1200px) {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  @media screen and (max-width: 500px) {
    padding: 12px;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 30px;
  }

  .testimonial {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    width: 100%;
    font-weight: 400;
    line-height: 24px;
    padding: 8px;
    border-bottom: 1px solid #E0E0E0;

    &:last-child {
      border-bottom: none;
    }

    .card {
      display: flex;
      margin-top: 18px;
      margin-bottom: 22px;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #E0E0E0;
      }

      .signature {
        margin-left: 20px;
        color: #4F4F4F;
        font-size: 12px;
        line-height: 16px;

        .name {
          padding-bottom: 3px;
          padding-top: 2px;
          font-weight: 600;
        }

        .position {
          font-weight: 400;
        }
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }

  .testimonial {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 8px;
    border-bottom: 1px solid #E0E0E0;

    &:last-child {
      border-bottom: none;
    }

    .card {
      display: flex;
      margin-top: 18px;
      margin-bottom: 22px;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #E0E0E0;
      }

      .signature {
        margin-left: 20px;
        color: #4F4F4F;
        font-size: 12px;
        line-height: 16px;

        .name {
          padding-bottom: 3px;
          padding-top: 2px;
          font-weight: 600;
        }

        .position {
          font-weight: 400;
        }
      }
    }
  }
}

.form {
//  gap: 24px;
//  display: flex;
//  flex-direction: column;
//  align-items: flex-start;
display: block;
}

.inputWrapper {
  width: 100%;
}

.form_width {
  max-width: 750px;
  width: 50%;
  padding: 48px 48px 64px 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 64px;
  flex: 1 0 0;
  align-self: stretch;
  flex-direction: column;
  @media screen and (max-width: 1200px) {
    width: 100%;
    padding: 22px 22px 22px 22px;
  }
}

@media (max-width: 1024px) {
  .base {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 15px;
  }

  .left_side {
    line-height: 50px;
    text-align: center;
    .subtext {
      line-height: 1.5em;
      ul {
        list-style-type: none;
        li {
          text-align: center;
        }
      }
    }
    .msg {
      margin-top: 30px;

      line-height: 30px;
    }
  }

  .white_box {
    padding: 20px;
    .google_btn {
      font-size: 1px;
    }
  }
}
